import '../public/main/config'
import Vue from 'vue';
import VueRouter from "vue-router";
import App from './views/platform/App.vue';
import './views/platform/config/autoLoad';


const routes = [{
    name        : "index",
    path        : "/index",
    component   : () => import( `@/views/platform/index` ) ,
}];


routes.push( {
    path : '*',
    name: '404',
    component : () => import( `@/views/platform/404` )
} )

const router = new VueRouter({
    mode: 'history',
    // base: `${process.env.BASE_URL}/platform` , //项目部署路径
    base: `${process.env.BASE_URL}` , //项目部署路径
    routes,
    scrollBehavior( to, from, savedPosition ){
        return {x:0,y:0}
    }
})

router.beforeEach((to, from, next) => {
    if( to.path == "/" ){
        next( `/index` );
    }else {
        let _isLogin  = sessionStorage.getItem( "USER_ISLOGIN" ) == 'true';
        if( to.meta?.reqAuth === true && !_isLogin )
            next( '/index' );
        else
            next();
    }
});

Vue.use( VueRouter )

Vue.config.productionTip = false

window.$app             = {};
window.$app.router      = router;
window.localUrl         = `${location.protocol}//${location.host}/${window.config.basePath}`

import loadGlobalConf from "./config/loadGlobalConf";
import P404 from "./views/404";


loadGlobalConf.load( webConf => {
    /*启动VUE*/
    window.config   = Object.assign( window.config, webConf );
    window.Vue      =  new Vue({
        router,
        render: h => h(App)
    }).$mount('#app')
} );


$( '#app-loading' ).hide();
if ('serviceWorker' in navigator) {
    // Use the window load event to keep the page load performant
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js');
    });
}
