/**
 * 自动加载各种默认组件 及配置文件
 */
import Vue from 'vue';
import $ from 'jquery';
import store from "@/config/store";
import cookies from 'js-cookie';
import Element from 'element-ui';
// import * as genesysReader from "@genesys/reader";
import 'element-ui/lib/theme-chalk/index.css';

import apiAxios from "@/config/apiAxios";
import globalFunc from '@/config/global.func';


/*致远速联基础样式库*/
import '@icon-park/vue/styles/index.css';
import {install} from '@icon-park/vue/es/all';
install( Vue, "icon" );
// genesysReader.install( Vue );


Vue.use( Element,{size:"medium"} );
Vue.use( cookies );

window.$                = $;
Vue.prototype.$api 		= apiAxios;
Vue.prototype.$func 	= globalFunc;
Vue.prototype.$store 	= store;
Vue.prototype.$cookies 	= cookies;
Vue.prototype.$EventBus = new Vue();

Vue.config.errorHandler = function( e, vm ){
    console.error( e )
}



const on = Vue.prototype.$on;
Vue.prototype.$on = function (event, func) {
	  let timer
	  let newFunc = func
	  let isFirst = true;
	  let isEnd = false
	  if (event == 'click') {
	    newFunc = function () {
		  if(timer){ console.log('操作太频繁了吧...') }
		  if(!timer && isFirst) {
			  // 执行第一次
			  func.apply(this, arguments);
		  }

	      clearTimeout(timer)
		  timer = setTimeout(function () {
				// 不执行最后一次
				// func.apply(this, arguments)
				timer = null
		  }, 500)
	    }
	  }
	  on.call(this, event, newFunc)
}


window.$store   = store;
window.$func    = globalFunc;

import '@/config/vueExtends';
